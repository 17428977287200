import { defaultTo, get, isEmpty } from "lodash";
import {
  PaymentMethodLabel,
  PaymentMethodsEnum,
} from "../infraestructure/payment-method";
import { formatNumber } from "./transaction_list_utils";
import {
  ContentCard,
  responseJson,
} from "../../components/CardInformation/CardInformation.interfaces";
import { TransactionInfo } from "../../../types/transactions_data";
import {
  CardInformationEnum,
  CardSectionValueEnum,
  FieldAuthorizerSectionValueEnum,
  FieldBillingDataSectionValueEnum,
  FieldCardSectionValueEnum,
  FieldMerchantDataSectionValueEnum,
  FieldPaymentCredentialSectionValueEnum,
  SectionTitleEnum,
} from "../enums/CardInformationEnum";
import {
  TransactionTypeEnum,
  TransactionTypeLabelEnum,
  TransactionTypeValue,
} from "../enums/TransactionTypeEnum";
import { TransactionType } from "../infraestructure/transaction-type";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../infraestructure/time-zone-catalogues";
import { HOUR_MINUS_5, UTC } from "../constants/TimeZoneDefault";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import { getCardType } from "./general_utils";

export function validationNull(valor: string | null): string {
  return valor === null ? "N/A" : valor;
}

const mapPaymentMethods = (paymentMethod: string | undefined): string => {
  if (!paymentMethod) return "";

  return PaymentMethodLabel[paymentMethod];
};
const setPaymentMethods = (
  methods: string[] | string,
  isArrayPayment: boolean
): string[] => {
  if (isArrayPayment)
    return (methods as string[]).map((method: string) => {
      return mapPaymentMethods(method);
    });

  return [""];
};

const mapTransactionType = (transactionType: string): string => {
  switch (transactionType) {
    case TransactionTypeEnum.SALE:
      return TransactionTypeLabelEnum.SALE;
    case TransactionTypeEnum.VOID:
      return TransactionTypeLabelEnum.VOID;
    case TransactionTypeEnum.VOID_PARTIAL:
      return TransactionTypeLabelEnum.VOID_PARTIAL;
    case TransactionTypeEnum.DEFERRED:
      return TransactionTypeLabelEnum.DEFERRED;
    case TransactionTypeEnum.CREDIT:
      return TransactionTypeLabelEnum.CREDIT;
    case TransactionTypeEnum.PAYOUT:
      return TransactionTypeLabelEnum.PAYOUT;
    case TransactionTypeEnum.DEBIT:
      return TransactionTypeLabelEnum.DEBIT;
    case TransactionTypeEnum.CHARGEBACK:
      return TransactionTypeLabelEnum.CHARGEBACK;
    case TransactionTypeEnum.PREAUTHORIZATION:
      return TransactionTypeLabelEnum.PREAUTHORIZATION;
    case TransactionTypeEnum.CAPTURE:
      return TransactionTypeLabelEnum.CAPTURE;
    default:
      return "N/A";
  }
};

const approvalAmount = (
  status: string,
  type: string,
  converted_amount: number,
  request_amount: number,
  transaction_amount: number,
  currency: string
): string => {
  let getApprovalAmount: string;

  if (
    status === TransactionType.DECLINED ||
    type === TransactionType.VOID ||
    type === TransactionType.VOID_PARTIAL
  ) {
    if (converted_amount) {
      getApprovalAmount = formatNumber(converted_amount.toString());
    } else {
      if (request_amount) {
        getApprovalAmount = formatNumber(request_amount.toString());
      } else {
        getApprovalAmount = CardInformationEnum.DEFAULT_VALUE;
      }
    }
  } else {
    if (transaction_amount) {
      getApprovalAmount = formatNumber(transaction_amount.toString());
    } else {
      getApprovalAmount = CardInformationEnum.DEFAULT_VALUE;
    }
  }

  return `${getApprovalAmount} ${currency}`;
};
const buildTotal = (
  status: string,
  type: string,
  request_amount: number,
  transaction_amount: number,
  currency: string
): string => {
  let getAmount: string;

  if (
    status === TransactionType.DECLINED ||
    type === TransactionType.VOID ||
    type === TransactionType.VOID_PARTIAL
  ) {
    if (request_amount) {
      getAmount = formatNumber(request_amount.toString());
    } else {
      getAmount = CardInformationEnum.DEFAULT_VALUE;
    }
  } else {
    if (transaction_amount) {
      getAmount = formatNumber(transaction_amount.toString());
    } else {
      getAmount = CardInformationEnum.DEFAULT_VALUE;
    }
  }

  return `${getAmount} ${currency}`;
};

export const jsonToString = (
  transactionInfo: TransactionInfo
): responseJson => {
  const metadata_obj: {
    metadata: object;
    subscriptionMetadata: object;
  } = {
    metadata: get(transactionInfo, "metadata", {}),
    subscriptionMetadata: get(transactionInfo, "subscription_metadata", {}),
  };
  const taxes_obj: {
    taxes: object;
  } = {
    taxes: get(transactionInfo, "taxes", {}),
  };

  const metadataCredentials_obj: {
    metadata: object;
  } = {
    metadata: get(transactionInfo, "credential_metadata", {}),
  };
  let metadata: string;
  let metadataCredentials: string;
  let taxes: string;
  let value_tax: number = 0;

  if (
    get(transactionInfo, "payment_method", "") === PaymentMethodsEnum.CARD ||
    get(transactionInfo, "subscription_id", "") === ""
  ) {
    Reflect.deleteProperty(metadata_obj, "subscriptionMetadata");
  }

  for (let i in taxes_obj.taxes) {
    if (taxes_obj.taxes.hasOwnProperty(i)) {
      value_tax += taxes_obj.taxes[i];
    }
  }
  metadata = JSON.stringify(metadata_obj, null, " ");
  metadataCredentials = JSON.stringify(metadataCredentials_obj, null, " ");
  taxes = JSON.stringify(taxes_obj, null, " ");

  return {
    metadata,
    metadataCredentials,
    taxes,
    value_tax: value_tax.toString(),
  };
};

const getResponseCode = (transactionInfo: TransactionInfo): number => {
  const responseCode = defaultTo(get(transactionInfo, "response_code"), 0);
  const processorCode = get(transactionInfo, "processor_code");

  return processorCode !== undefined && !isEmpty(processorCode)
    ? processorCode
    : responseCode;
};

const getResponseText = (transactionInfo: TransactionInfo): string => {
  const responseText = get(transactionInfo, "response_text", "N/A");
  const processorMessage = get(transactionInfo, "processor_message");

  return processorMessage !== undefined ? processorMessage : responseText;
};

const getPayoutsDetailData = (
  transactionInfo: TransactionInfo,
  currency: string,
  taxes: number
): ContentCard[] => {
  return [
    {
      body: [
        {
          label: CardInformationEnum.MERCHANT_NAME,
          text: get(transactionInfo, "merchant_name", ""),
          type: FieldMerchantDataSectionValueEnum.MERCHANT_NAME,
        },
        {
          label: CardInformationEnum.ID_MERCHANT,
          text: get(transactionInfo, "merchant_id", ""),
          type: FieldMerchantDataSectionValueEnum.ID_MERCHANT,
        },
      ],
      title: SectionTitleEnum.MERCHANT_DATA,
      type: CardSectionValueEnum.MERCHANT_DATA,
    },
    {
      body: [
        {
          label: CardInformationEnum.CLIENT,
          text: get(transactionInfo, "card_holder_name", ""),
          type: FieldBillingDataSectionValueEnum.CLIENT,
        },
        {
          label: CardInformationEnum.TYPE,
          text: TransactionTypeValue.PAYOUT,
          type: FieldMerchantDataSectionValueEnum.TYPE,
        },
        {
          label: CardInformationEnum.APPROVAL_NO,
          text: get(transactionInfo, "approval_code", "N/A"),
          type: FieldMerchantDataSectionValueEnum.APPROVAL_NO,
        },
        {
          label: CardInformationEnum.SUBTOTAL,
          text: buildTotal(
            get(transactionInfo, "transaction_status")!,
            mapTransactionType(get(transactionInfo, "transaction_type", "")),
            get(transactionInfo, "request_amount")!,
            get(transactionInfo, "approved_transaction_amount")!,
            currency
          ),
          type: FieldMerchantDataSectionValueEnum.SUBTOTAL,
        },
        {
          label: CardInformationEnum.TAXES,
          text: `${formatNumber(taxes.toString())} ${currency}`,
          type: FieldMerchantDataSectionValueEnum.TAXES,
        },
        {
          label: CardInformationEnum.TOTAL,
          text: buildTotal(
            get(transactionInfo, "transaction_status")!,
            mapTransactionType(get(transactionInfo, "transaction_type", "")),
            get(transactionInfo, "request_amount")!,
            get(transactionInfo, "approved_transaction_amount")!,
            currency
          ),
          type: FieldMerchantDataSectionValueEnum.TOTAL,
        },
      ],
      title: SectionTitleEnum.TRANSACTION_DATA,
      type: CardSectionValueEnum.TRANSACTION_DATA_RESUME,
    },
    {
      body: [
        {
          label: CardInformationEnum.CREDENTIAL,
          text: get(transactionInfo, "credential_alias", ""),
          type: FieldPaymentCredentialSectionValueEnum.CREDENTIAL,
        },
        {
          label: CardInformationEnum.CREDENTIAL_ID,
          text: get(transactionInfo, "credential_id", ""),
          type: FieldPaymentCredentialSectionValueEnum.CREDENTIAL_ID,
        },
        {
          label: CardInformationEnum.PUBLIC_KEY,
          text: get(transactionInfo, "public_credential_id", ""),
          type: FieldPaymentCredentialSectionValueEnum.PUBLIC_KEY,
        },
      ],
      title: SectionTitleEnum.CREDENTIAL_DATA,
      type: CardSectionValueEnum.PAYMENT_CREDENTIAL,
    },
    {
      body: [
        {
          label: CardInformationEnum.SUBTOTAL_IVA,
          text: `${formatNumber(
            get(transactionInfo, "subtotal_iva", 0).toString()
          )} ${currency}`,
          type: FieldBillingDataSectionValueEnum.SUBTOTAL_IVA,
        },
        {
          label: CardInformationEnum.SUBTOTAL_IVA_0,
          text: approvalAmount(
            get(transactionInfo, "transaction_status")!,
            mapTransactionType(get(transactionInfo, "transaction_type", "")),
            get(transactionInfo, "converted_amount")!,
            get(transactionInfo, "request_amount")!,
            get(transactionInfo, "approved_transaction_amount")!,
            currency
          ),
          type: FieldBillingDataSectionValueEnum.SUBTOTAL_IVA_0,
        },
        {
          label: CardInformationEnum.IVA_AMOUNT,
          text: `${formatNumber(
            get(transactionInfo, "iva_value", 0).toString()
          )} ${currency}`,
          type: FieldBillingDataSectionValueEnum.IVA_AMOUNT,
        },
        {
          label: CardInformationEnum.ICE_AMOUNT,
          text: `${formatNumber(
            get(transactionInfo, "ice_value", 0).toString()
          )} ${currency}`,
          type: FieldBillingDataSectionValueEnum.ICE_AMOUNT,
        },
        {
          label: CardInformationEnum.APPROVAL_AMOUNT,
          text: approvalAmount(
            get(transactionInfo, "transaction_status")!,
            mapTransactionType(get(transactionInfo, "transaction_type", "")),
            get(transactionInfo, "converted_amount")!,
            get(transactionInfo, "request_amount")!,
            get(transactionInfo, "approved_transaction_amount")!,
            currency
          ),
          type: FieldBillingDataSectionValueEnum.APPROVAL_AMOUNT,
        },
      ],
      title: SectionTitleEnum.BILLING_DATA,
      type: CardSectionValueEnum.BILLING_DATA,
    },
    {
      body: [
        {
          label: CardInformationEnum.BRAND,
          text: get(transactionInfo, "payment_brand", ""),
          type: FieldCardSectionValueEnum.BRAND,
        },
        {
          label: CardInformationEnum.CARD_TYPE,
          text: getCardType(get(transactionInfo, "card_type", "")),
          type: FieldCardSectionValueEnum.CARD_TYPE,
        },
        {
          label: CardInformationEnum.ISSUER_BANK,
          text: get(transactionInfo, "issuing_bank", ""),
          type: FieldCardSectionValueEnum.ISSUER_BANK,
        },
      ],
      title: SectionTitleEnum.CARD_DATA,
      type: CardSectionValueEnum.CARD_DATA,
    },
    {
      body: [
        {
          label: CardInformationEnum.PROCESSOR,
          text: get(transactionInfo, "processor_name", ""),
          type: FieldAuthorizerSectionValueEnum.PROCESSOR,
        },
        {
          label: CardInformationEnum.RESPONSE_CODE,
          text: validationNull(getResponseCode(transactionInfo).toString()),
          type: FieldAuthorizerSectionValueEnum.RESPONSE_CODE,
        },
        {
          label: CardInformationEnum.RESPONSE_MESSAGE,
          text: validationNull(getResponseText(transactionInfo)),
          type: FieldAuthorizerSectionValueEnum.RESPONSE_MESSAGE,
        },
      ],
      title: SectionTitleEnum.AUTHORIZATION_DATA,
      type: CardSectionValueEnum.AUTHORIZER_DATA,
    },
  ];
};

const getGeneralDetailData = (
  transactionInfo: TransactionInfo,
  getPaymentMethod: string[] | string,
  isArrayPayment: boolean,
  paymentMethod: string,
  subTotal: number,
  taxes: number,
  currency: string
): ContentCard[] => {
  return [
    {
      body: [
        {
          label: CardInformationEnum.MERCHANT,
          text: get(transactionInfo, "merchant_name", ""),
          type: FieldMerchantDataSectionValueEnum.MERCHANT,
        },
        {
          label: CardInformationEnum.MERCHANT_ID,
          text: get(transactionInfo, "merchant_id", ""),
          type: FieldMerchantDataSectionValueEnum.MERCHANT_ID,
        },
        {
          label: CardInformationEnum.DOC_NUMBER,
          text: get(transactionInfo, "document_number", ""),
          type: FieldMerchantDataSectionValueEnum.DOC_NUMBER,
        },
        {
          label: CardInformationEnum.TYPE,
          text: get(transactionInfo, "transaction_type", ""),
          type: FieldMerchantDataSectionValueEnum.TYPE,
        },
        {
          isArray: Array.isArray(getPaymentMethod),
          itemText: setPaymentMethods(getPaymentMethod, isArrayPayment),
          label: CardInformationEnum.PAYMENT_METHOD,
          text: paymentMethod,
          type: FieldMerchantDataSectionValueEnum.PAYMENT_METHOD,
        },
        {
          label: CardInformationEnum.BANK,
          text: get(transactionInfo, "bank_name", "N/A"),
          type: FieldMerchantDataSectionValueEnum.BANK,
        },
        {
          label: CardInformationEnum.PROCESSOR,
          text: get(transactionInfo, "processor_name", ""),
          type: FieldMerchantDataSectionValueEnum.PROCESSOR,
        },
        {
          label: CardInformationEnum.CLIENT_ID,
          text: get(transactionInfo, "identification", "N/A"),
          type: FieldMerchantDataSectionValueEnum.CLIENT_ID,
        },
        {
          label: CardInformationEnum.APPROVAL_NO,
          text: get(transactionInfo, "approval_code", "N/A"),
          type: FieldMerchantDataSectionValueEnum.APPROVAL_NO,
        },
        {
          label: CardInformationEnum.SUBTOTAL,
          text: `${formatNumber(subTotal.toString())} ${currency}`,
          type: FieldMerchantDataSectionValueEnum.SUBTOTAL,
        },
        {
          label: CardInformationEnum.TAXES,
          text: `${formatNumber(taxes.toString())} ${currency}`,
          type: FieldMerchantDataSectionValueEnum.TAXES,
        },
        {
          label: CardInformationEnum.TOTAL,
          text: buildTotal(
            get(transactionInfo, "transaction_status")!,
            mapTransactionType(get(transactionInfo, "transaction_type", "")),
            get(transactionInfo, "request_amount")!,
            get(transactionInfo, "approved_transaction_amount")!,
            currency
          ),
          type: FieldMerchantDataSectionValueEnum.TOTAL,
        },
        {
          label: CardInformationEnum.PAYMENT_PIN,
          text: get(transactionInfo, "pin", ""),
          type: FieldMerchantDataSectionValueEnum.PAYMENT_PIN,
        },
        {
          label: CardInformationEnum.DESCRIPTION,
          text: get(transactionInfo, "description", ""),
          type: FieldMerchantDataSectionValueEnum.DESCRIPTION,
        },
      ],
      type: CardSectionValueEnum.MERCHANT_DATA,
    },
    {
      body: [
        {
          label: CardInformationEnum.SUBTOTAL_IVA,
          text: `${formatNumber(
            get(
              transactionInfo,
              "converted_subtotal_iva",
              get(transactionInfo, "subtotal_iva", 0)
            ).toString()
          )} ${currency}`,
          type: FieldBillingDataSectionValueEnum.SUBTOTAL_IVA,
        },
        {
          label: CardInformationEnum.SUBTOTAL_IVA_0,
          text: `${formatNumber(
            get(
              transactionInfo,
              "converted_subtotal_iva0",
              get(transactionInfo, "subtotal_iva0", 0)
            ).toString()
          )} ${currency}`,
          type: FieldBillingDataSectionValueEnum.SUBTOTAL_IVA_0,
        },
        {
          label: CardInformationEnum.IVA_AMOUNT,
          text: `${formatNumber(
            get(
              transactionInfo,
              "converted_iva",
              get(transactionInfo, "iva_value", 0)
            ).toString()
          )} ${currency}`,
          type: FieldBillingDataSectionValueEnum.IVA_AMOUNT,
        },
        {
          label: CardInformationEnum.ICE_AMOUNT,
          text: `${formatNumber(
            get(transactionInfo, "ice_value", 0).toString()
          )} ${currency}`,
          type: FieldBillingDataSectionValueEnum.ICE_AMOUNT,
        },
        {
          label: CardInformationEnum.APPROVAL_AMOUNT,
          text: approvalAmount(
            get(transactionInfo, "transaction_status")!,
            mapTransactionType(get(transactionInfo, "transaction_type", "")),
            get(transactionInfo, "converted_amount")!,
            get(transactionInfo, "request_amount")!,
            get(transactionInfo, "approved_transaction_amount")!,
            currency
          ),
          type: FieldBillingDataSectionValueEnum.APPROVAL_AMOUNT,
        },
        {
          label: CardInformationEnum.CLIENT,
          text:
            get(transactionInfo, "contact_details.first_name", "") +
            get(transactionInfo, "contact_details.last_name", ""),
          type: FieldBillingDataSectionValueEnum.CLIENT,
        },
        {
          label: CardInformationEnum.IDENTIFICATION_TYPE,
          text: get(transactionInfo, "contact_details.document_type", ""),
          type: FieldBillingDataSectionValueEnum.IDENTIFICATION_TYPE,
        },
        {
          label: CardInformationEnum.IDENTIFICATION_NUMBER,
          text: get(transactionInfo, "contact_details.document_number", ""),
          type: FieldBillingDataSectionValueEnum.IDENTIFICATION_NUMBER,
        },

        {
          label: CardInformationEnum.EMAIL,
          text: get(transactionInfo, "contact_details.email", ""),
          type: FieldBillingDataSectionValueEnum.EMAIL,
        },
        {
          label: CardInformationEnum.PHONE_NUMBER,
          text: get(transactionInfo, "contact_details.phone_number", ""),
          type: FieldBillingDataSectionValueEnum.PHONE_NUMBER,
        },
      ],
      type: CardSectionValueEnum.BILLING_DATA,
    },
  ];
};

const buildCardInfo = (
  transactionInfo: TransactionInfo,
  getPaymentMethod: string[] | string,
  isArrayPayment: boolean,
  subTotal: number,
  currency: string,
  taxes: number
): ContentCard[] => {
  let data: ContentCard[] = [] as ContentCard[];
  const cardPaymentMethod: string = PaymentMethodEnum.CARD.toUpperCase();
  const trxPaymentMethod: string = get(
    transactionInfo,
    "payment_method",
    ""
  ).toUpperCase();
  const trxTransactionType: string = get(
    transactionInfo,
    "transaction_type",
    ""
  ).toUpperCase();
  const paymentMethod: string =
    typeof getPaymentMethod === "string"
      ? mapPaymentMethods(getPaymentMethod)
      : mapPaymentMethods("");

  if (!isEmpty(transactionInfo)) {
    if (
      trxPaymentMethod === cardPaymentMethod &&
      trxTransactionType === TransactionTypeEnum.PAYOUT
    ) {
      data = getPayoutsDetailData(transactionInfo, currency, taxes);
    } else {
      data = getGeneralDetailData(
        transactionInfo,
        getPaymentMethod,
        isArrayPayment,
        paymentMethod,
        subTotal,
        taxes,
        currency
      );
    }
  }

  return data;
};

export const buildCardInformation = (
  transactionInfo: TransactionInfo
): ContentCard[] => {
  const getPaymentMethod: string[] | string = get(
    transactionInfo,
    "payment_method",
    ""
  );
  const isArrayPayment: boolean = Array.isArray(getPaymentMethod);
  const currency: string = isEmpty(get(transactionInfo, "converted_currency"))
    ? get(transactionInfo, "currency_code", "")
    : get(transactionInfo, "converted_currency", "");
  const subTotalIva: number = get(
    transactionInfo,
    "converted_subtotal_iva",
    get(transactionInfo, "subtotal_iva", 0)
  );
  const subTotalIavO: number = get(
    transactionInfo,
    "converted_subtotal_iva0",
    get(transactionInfo, "subtotal_iva0", 0)
  );
  const subTotal: number = subTotalIva + subTotalIavO;
  const ivaValue: number = get(
    transactionInfo,
    "converted_iva",
    get(transactionInfo, "iva_value", 0)
  );
  const iceValue: number = get(transactionInfo, "ice_value", 0);

  const taxes: number =
    ivaValue + iceValue + Number(jsonToString(transactionInfo).value_tax);

  return buildCardInfo(
    transactionInfo,
    getPaymentMethod,
    isArrayPayment,
    subTotal,
    currency,
    taxes
  );
};

export const timeZoneHour = (): string => {
  const text: string | undefined = localStorage.getItem("time-zone")!;
  const value: string = "region";
  let index: number;
  const _timeZoneDefault: string = "America/Guayaquil";

  if (text === undefined)
    return value === UTC ? HOUR_MINUS_5 : _timeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === UTC
      ? HOUR_MINUS_5
      : _timeZoneDefault;
  }

  return value === UTC ? HOUR_MINUS_5 : _timeZoneDefault;
};
