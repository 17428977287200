import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { PaymentMethodsEnum } from "../infraestructure/payment-method";
import { ContentInformation } from "../../components/CardInformation/CardInformation.interfaces";
import {
  CardSectionValueEnum,
  FieldAuthorizerSectionValueEnum,
  FieldBillingDataSectionValueEnum,
  FieldCardSectionValueEnum,
  FieldMerchantDataSectionValueEnum,
  FieldPaymentCredentialSectionValueEnum,
} from "../enums/CardInformationEnum";

const getContentInformationByFilter = (
  sectionList: ContentInformation[],
  filters: string[]
): ContentInformation[] =>
  sectionList.filter((section) =>
    filters.find((filter) => section.type === filter)
  );

export const catalogSectionFieldListByTransaction = (
  sectionList: ContentInformation[]
): Record<string, object> => {
  const sectionFieldListTransferMerchantData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldMerchantDataSectionValueEnum.MERCHANT,
      FieldMerchantDataSectionValueEnum.MERCHANT_ID,
      FieldMerchantDataSectionValueEnum.DOC_NUMBER,
      FieldMerchantDataSectionValueEnum.TYPE,
      FieldMerchantDataSectionValueEnum.PAYMENT_METHOD,
      FieldMerchantDataSectionValueEnum.BANK,
      FieldMerchantDataSectionValueEnum.SUBTOTAL,
      FieldMerchantDataSectionValueEnum.TAXES,
      FieldMerchantDataSectionValueEnum.TOTAL,
    ]);

  const sectionFieldListTransferBillingData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldBillingDataSectionValueEnum.SUBTOTAL_IVA,
      FieldBillingDataSectionValueEnum.SUBTOTAL_IVA_0,
      FieldBillingDataSectionValueEnum.IVA_AMOUNT,
      FieldBillingDataSectionValueEnum.ICE_AMOUNT,
      FieldBillingDataSectionValueEnum.APPROVAL_AMOUNT,
    ]);

  const sectionFieldListCashMerchantData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldMerchantDataSectionValueEnum.MERCHANT,
      FieldMerchantDataSectionValueEnum.MERCHANT_ID,
      FieldMerchantDataSectionValueEnum.PAYMENT_METHOD,
      FieldMerchantDataSectionValueEnum.TOTAL,
      FieldMerchantDataSectionValueEnum.PAYMENT_PIN,
      FieldMerchantDataSectionValueEnum.DESCRIPTION,
    ]);

  const sectionFieldListCashBillingData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldBillingDataSectionValueEnum.CLIENT,
      FieldBillingDataSectionValueEnum.IDENTIFICATION_TYPE,
      FieldBillingDataSectionValueEnum.IDENTIFICATION_NUMBER,
      FieldBillingDataSectionValueEnum.EMAIL,
      FieldBillingDataSectionValueEnum.PHONE_NUMBER,
    ]);

  const sectionFieldListCardPayoutsMerchantData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldMerchantDataSectionValueEnum.MERCHANT_NAME,
      FieldMerchantDataSectionValueEnum.ID_MERCHANT,
    ]);

  const sectionFieldListCardPayoutsTransactionData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldBillingDataSectionValueEnum.CLIENT,
      FieldMerchantDataSectionValueEnum.TYPE,
      FieldMerchantDataSectionValueEnum.APPROVAL_NO,
      FieldMerchantDataSectionValueEnum.SUBTOTAL,
      FieldMerchantDataSectionValueEnum.TAXES,
      FieldMerchantDataSectionValueEnum.TOTAL,
    ]);

  const sectionFieldListCardPayoutsPaymentCredentialData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldPaymentCredentialSectionValueEnum.CREDENTIAL,
      FieldPaymentCredentialSectionValueEnum.CREDENTIAL_ID,
      FieldPaymentCredentialSectionValueEnum.PUBLIC_KEY,
    ]);

  const sectionFieldListCardPayoutsBillingData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldBillingDataSectionValueEnum.SUBTOTAL_IVA,
      FieldBillingDataSectionValueEnum.SUBTOTAL_IVA_0,
      FieldBillingDataSectionValueEnum.IVA_AMOUNT,
      FieldBillingDataSectionValueEnum.ICE_AMOUNT,
      FieldBillingDataSectionValueEnum.APPROVAL_AMOUNT,
    ]);

  const sectionFieldListCardPayoutsCardData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldCardSectionValueEnum.BRAND,
      FieldCardSectionValueEnum.CARD_TYPE,
      FieldCardSectionValueEnum.ISSUER_BANK,
    ]);

  const sectionFieldListCardPayoutsAuthorizerData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldAuthorizerSectionValueEnum.PROCESSOR,
      FieldAuthorizerSectionValueEnum.RESPONSE_CODE,
      FieldAuthorizerSectionValueEnum.RESPONSE_MESSAGE,
    ]);

  const approval_state = {
    [PaymentMethodsEnum.CASH]: {
      [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldListCashMerchantData,
      [CardSectionValueEnum.BILLING_DATA]: sectionFieldListCashBillingData,
    },
    [PaymentMethodsEnum.TRANSFER]: {
      [CardSectionValueEnum.MERCHANT_DATA]:
        sectionFieldListTransferMerchantData,
      [CardSectionValueEnum.BILLING_DATA]: sectionFieldListTransferBillingData,
    },
    [PaymentMethodsEnum.CARD]: {
      [CardSectionValueEnum.MERCHANT_DATA]:
        sectionFieldListCardPayoutsMerchantData,
      [CardSectionValueEnum.TRANSACTION_DATA_RESUME]:
        sectionFieldListCardPayoutsTransactionData,
      [CardSectionValueEnum.PAYMENT_CREDENTIAL]:
        sectionFieldListCardPayoutsPaymentCredentialData,
      [CardSectionValueEnum.BILLING_DATA]:
        sectionFieldListCardPayoutsBillingData,
      [CardSectionValueEnum.CARD_DATA]: sectionFieldListCardPayoutsCardData,
      [CardSectionValueEnum.AUTHORIZER_DATA]:
        sectionFieldListCardPayoutsAuthorizerData,
    },
  };

  const fieldList: Record<string, object> = {
    [TransactionStatusEnum.APPROVAL]: approval_state,
    [TransactionStatusEnum.APPROVED]: approval_state,
    [TransactionStatusEnum.INITIALIZED]: {
      [PaymentMethodsEnum.CASH]: {
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldListCashMerchantData,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldListCashBillingData,
      },
      [PaymentMethodsEnum.TRANSFER]: {
        [CardSectionValueEnum.MERCHANT_DATA]:
          sectionFieldListTransferMerchantData,
        [CardSectionValueEnum.BILLING_DATA]:
          sectionFieldListTransferBillingData,
      },
    },
    [TransactionStatusEnum.DECLINED]: {
      [PaymentMethodsEnum.CASH]: {
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldListCashMerchantData,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldListCashBillingData,
      },
      [PaymentMethodsEnum.TRANSFER]: {
        [CardSectionValueEnum.MERCHANT_DATA]:
          sectionFieldListTransferMerchantData,
        [CardSectionValueEnum.BILLING_DATA]:
          sectionFieldListTransferBillingData,
      },
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.MERCHANT_DATA]:
          sectionFieldListCardPayoutsMerchantData,
        [CardSectionValueEnum.TRANSACTION_DATA_RESUME]:
          sectionFieldListCardPayoutsTransactionData,
        [CardSectionValueEnum.PAYMENT_CREDENTIAL]:
          sectionFieldListCardPayoutsPaymentCredentialData,
        [CardSectionValueEnum.BILLING_DATA]:
          sectionFieldListCardPayoutsBillingData,
        [CardSectionValueEnum.CARD_DATA]: sectionFieldListCardPayoutsCardData,
        [CardSectionValueEnum.AUTHORIZER_DATA]:
          sectionFieldListCardPayoutsAuthorizerData,
      },
    },
  };

  return fieldList;
};
